import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { TOKEN_KEY } from 'utils/constants'
import { getItem } from 'utils/persistenceUtils'

const PrivateRoute = ({ ...rest }: RouteProps) => {
  const auth = getItem(TOKEN_KEY)
  if (!auth) return <Redirect to="login" />
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} />
}

export default PrivateRoute
