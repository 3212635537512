const URL_LOGO = `${process.env.REACT_APP_IMAGES}/logos`

export const LOGO_WAVECODE_WHITE = `${URL_LOGO}/logo.png`
export const LOGO_SMALL_WAVECODE_WHITE = `${URL_LOGO}/logoMini.png`
export const LOGO_WAVECODE_BLUE = `${URL_LOGO}/logo_blue.png`
export const TOKEN_KEY = 'wc-token'
export const routes = {
  DEFAULT: '/',
  LOGIN: '/login',
  DASHBOARD: '/painel',
  CLIENTS: '/clients',
  COMPANIES_CLIENTS: '/clients-companies/:idCompany?',
  COMPANIES: '/companies',
  PRODUCTS: '/products',
  LOGS: '/logs_modulos',
}

export const USER_CONFIG = 'wc-user-config'

