import React, { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Theme from 'styles/theme'
import { routes, TOKEN_KEY } from 'utils/constants'
import { Loading } from 'wave-design-system-plus'
import { getItem } from 'utils/persistenceUtils'
import { defaultsHeadersAxios } from 'services/api'
import PrivateRoute from './PrivateRoutes'
import { useAreasAllActivities } from '../hook/useAreasAllActivities'

const Layout = lazy(() => import('components/Layout'))
const Login = lazy(() => import('pages/Login'))
const Painel = lazy(() => import('pages/Dashboard'))
const Clientes = lazy(() => import('pages/Clientes'))
const Companies_Clients = lazy(() => import('pages/Companies_Clients'))
const Companies = lazy(() => import('pages/Companies'))
const Products = lazy(() => import('pages/Products'))
const LogsModulos = lazy(() => import('pages/LogsModulos'))

const Routes = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { getAreasOfExpertise } = useAreasAllActivities() as any

  useEffect(() => {
    const token = getItem(TOKEN_KEY)
    if (token) {
      defaultsHeadersAxios(token)
      getAreasOfExpertise()
    } else if (pathname.split('/')[1] !== 'account-validation') {
      history.push(routes.LOGIN)
    }
  }, [])

  return (
    <>
      <Switch>
        <Suspense fallback={<Loading active />}>
          <Route exact path={routes.LOGIN} component={Login} />
          <Layout>
            <PrivateRoute exact path={routes.DEFAULT}>
              <Redirect to={routes.CLIENTS} />
            </PrivateRoute>
            <PrivateRoute path={routes.CLIENTS} component={Clientes} />
            <PrivateRoute path={routes.DASHBOARD} component={Painel} />
            <PrivateRoute path={routes.COMPANIES_CLIENTS} component={Companies_Clients} />
            <PrivateRoute path={routes.COMPANIES} component={Companies} />
            <PrivateRoute path={routes.PRODUCTS} component={Products} />
            <PrivateRoute path={routes.LOGS} component={LogsModulos} />
          </Layout>
        </Suspense>
      </Switch>
    </>
  )
}

export default Routes
